<template>
  <CRSelect
    :id="`${id}-rejection-method-filter-select`"
    v-model="rejectionMethodSelection"
    :items="rejectionMethods"
    chips
    flat
    hide-details
    item-text="rejectionMethod"
    item-value="rejectionMethod"
    :multiple="false"
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      statusFilterParent: null,
      statusFilterGrandParent: null,
      rejectionMethods: [
        {
          rejectionMethod: 'Manual',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: '00658c9d-ee03-421d-adfc-dd8de7c637aa',
                prop: 'rejectionMethod',
                filterType: 'eq',
              },
              value: 1,
            },
          ],
        },
        {
          rejectionMethod: 'Timed Out',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: 'cf68611d-02c5-4702-928c-22f2bd0e2622',
                prop: 'rejectionMethod',
                filterType: 'eq',
              },
              value: 2,
            },
          ],
        },
        {
          rejectionMethod: 'Ignored',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: 'd0725487-f620-4622-a4dd-ab54832a9634',
                prop: 'rejectionMethod',
                filterType: 'eq',
              },
              value: 3,
            },
          ],
        },
        {
          rejectionMethod: 'CharterUP-Cancellation',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: 'b9c27be8-bc69-11ed-afa1-0242ac120002',
                prop: 'referralStatus',
                filterType: 'eq',
              },
              value: 'rejected',
            },
            {
              column: {
                _t_id: 'c6b1bf26-bc69-11ed-afa1-0242ac120002',
                prop: 'cancellationParentClassificationId',
                filterType: 'eq',
              },
              value: 16,
            },
          ],
        },
        {
          rejectionMethod: 'Client-Cancellation',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: 'a1122606-bd19-11ed-afa1-0242ac120002',
                prop: 'rejectionMethod',
                filterType: 'isnull',
              },
              value: 0,
            },
            {
              column: {
                _t_id: 'ca56cdc4-bc69-11ed-afa1-0242ac120002',
                prop: 'referralStatus',
                filterType: 'eq',
              },
              value: 'rejected',
            },
            {
              column: {
                _t_id: 'cecf8a26-bc69-11ed-afa1-0242ac120002',
                prop: 'cancellationParentClassificationId',
                filterType: 'eq',
              },
              value: 17,
            },
          ],
        },
        {
          rejectionMethod: 'Self-Cancellation',
          rejectionMethodFilters: [
            {
              column: {
                _t_id: 'd20677fe-bc69-11ed-afa1-0242ac120002',
                prop: 'referralStatus',
                filterType: 'eq',
              },
              value: 'rejected',
            },
            {
              column: {
                _t_id: '70449187-38d2-4340-b5dc-89446ed8fac2',
                prop: 'operatorCancellationStatusKey',
                filterType: 'eq',
              },
              value: 'cancelled',
            },
            {
              column: {
                _t_id: '2b35082a-bd16-11ed-afa1-0242ac120002',
                prop: 'operatorCancellationClassificationId',
                filterType: 'isnotnull',
              },
              value: 1,
            },
          ],
        },
      ],
      rejectionMethodSelection: '',
    }
  },
  computed: {
    statusFilters() {
      let statusFilterNotCancelled = {
        column: {
          _t_id: 'dbd61f27-1c87-44c5-9c3f-01bbb8f496d6',
          prop: 'operatorCancellationStatusKey',
          method: 'or',
          filterType: 'neq',
        },
        value: 'cancelled',
      }

      let statusFilterIsNull = {
        column: {
          _t_id: '2eca1620-29dc-4396-84dc-60b4a8b6f35a',
          prop: 'operatorCancellationStatusKey',
          method: 'or',
          filterType: 'isnull',
        },
        value: 1,
      }
      return [statusFilterNotCancelled, statusFilterIsNull]
    },
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.removeFilters()

      if (this.parentFilter) {
        this.filters.remove(this.parentFilter)
      }

      const parent = this.filters.createParent('and')
      this.parentFilter = parent

      this.addRejectionMethodFilters()

      this.$emit('make-dirty-request')
      this.addFilter()
    },
    removeFilters() {
      if (this.rejectionMethodSelection != '') {
        const rejectionMethodFilters = this.rejectionMethods.find(
          (c) => c.rejectionMethod == this.rejectionMethodSelection
        ).rejectionMethodFilters

        for (const newFilter of rejectionMethodFilters) {
          const rejectionMethodFilter = deepClone(newFilter)
          this.filters.remove(rejectionMethodFilter)
        }

        if (this.statusFilterGrandParent) {
          for (const filter of this.statusFilters) {
            this.filters.remove(filter)
          }
          this.filters.remove(this.statusFilterParent)
          this.filters.remove(this.statusFilterGrandParent)

          this.statusFilterGrandParent = null
        }
      }
    },
    addRejectionMethodFilters() {
      if (this.rejectionMethodSelection != '') {
        const rejectionMethodFilters = this.rejectionMethods.find(
          (c) => c.rejectionMethod == this.rejectionMethodSelection
        ).rejectionMethodFilters

        for (const newFilter of rejectionMethodFilters) {
          const rejectionMethodFilter = deepClone(newFilter)
          rejectionMethodFilter.stepParent = this.column._t_id
          this.filters.add(this.parentFilter, rejectionMethodFilter)
        }

        if (
          ['CharterUP-Cancellation', 'Client-Cancellation'].includes(
            this.rejectionMethodSelection
          )
        ) {
          this.statusFilterGrandParent = this.filters.createParent('and')
          this.statusFilterParent = this.filters.createParent('or')
          for (const filter of this.statusFilters) {
            this.filters.add(this.statusFilterParent, filter)
          }
          this.filters.add(
            this.statusFilterGrandParent,
            this.statusFilterParent
          )

          this.statusFilterGrandParent.stepParent = this.column._t_id
          this.filters.add(this.parentFilter, this.statusFilterGrandParent)
        }
      }
    },
  },
}
</script>
