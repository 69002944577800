<template>
  <v-container fluid :class="{ 'call-center-margin': hasCallCenter }">
    <v-layout column sheet>
      <h1 class="page-header">Referrals</h1>
      <DataTable ref="adminReferralsTable" v-bind.sync="tableProps"></DataTable>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import DataTable from '@/components/DataTable.vue'
import ReservationTableDetail from '@/components/ReservationTableDetail.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import { capitalize } from '../utils/string'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  userPredefined,
} from '@/utils/predefined'
import { v4 } from 'uuid'
import RejectionMethodFilter from '@/components/RejectionMethodFilter.vue'

const categories = []
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'fully_offered' },
  { text: 'Accepted', value: 'fully_accepted' },
  { text: 'Confirmed', value: 'fully_confirmed' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]
const reservationStatusMap = [
  { text: 'Upcoming', value: 'upcoming' },
  { text: 'Started', value: 'started' },
  { text: 'Finished', value: 'finished' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Hold', value: 'hold' },
]
const rejectionMethodMap = [
  { text: 'Manual', value: 1 },
  { text: 'Timed Out', value: 2 },
  { text: 'Ignored', value: 3 },
]
const CHARTERUP_INITIATED_CANCELLATION_CLASSIFICATION_ID = 16
const CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID = 17
export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Referrals',
    }
  },
  data() {
    return {
      requestIdQueue: [],
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        enableExport: false,
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: false,
        canViewInactiveReservations: true,
        total: 0,
        tableId: 'admin_referral_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: ReservationTableDetail,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        categories,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        enableSelectableRows: false,
        enableSelectAll: false,
        selectedRows: [],
        id: 'admin-referral-list',
        initialFilters: [],
        ephemeralFilters: [],
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      referralsTVFilters: 'referrals/referralsTVFilters',
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group?.key === 'admin'
    await this.getColumns()

    this.tableProps.ephemeralFilters = this.referralsTVFilters
    this.$store.dispatch('referrals/clearReferralsFilters')

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
    EventBus.$on('successful-assignment', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations updated.`,
        })
      }, 1)
    })
    EventBus.$on('successful-clear-assignments', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations have been cleared.`,
        })
      }, 1)
    })
    EventBus.$on('successful-add-driver', (event) => {
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `A new driver ${event} has been created.`,
        })
      }, 1)
    })
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getColumns() {
      const columnsOut = []

      // NOTE: These columns are referenced by referralsFilters.js for Referral Rejections Report
      // If you change a column that is used as a drill down filter, update in the other file as well
      columnsOut.push(
        {
          _t_id: '9b20b167-a316-4241-b1a1-0fafb5f0ad74',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'startswith',
          sortProp: 'managedId',
          filterProp: 'managedId',
        },
        {
          _t_id: 'bb75296d-04ec-4b55-a923-340d19e0a83f',
          prop: 'companyName',
          text: 'Company Name',
          sort: true,
          filter: true,
          sortProp: 'company/name',
          filterProp: 'company/name',
          type: 'text',
          filterAsIs: true,
          filterType: 'contains',
        },
        {
          _t_id: '53167e2c-5cea-483f-b6c3-0a7dca1d1784',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: false,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
          forceOneLine: true,
        },
        {
          _t_id: '3111574e-f6a8-4edc-ab5c-ac1c8c087c79',
          prop: 'rejectedTime',
          text: 'Rejected Date',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: false,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
          forceOneLine: true,
        },
        {
          _t_id: '51216fcd-1176-468c-874d-3cc296efad52',
          prop: 'pickupDate',
          text: 'Pickup Date',
          computedText: (item, row) =>
            DateTime.fromISO(item, {
              zone: row.firstPickupTimeZone,
            }).toFormat('MM/dd/yyyy t ZZZZ'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          forceOneLine: true,
        },
        {
          _t_id: 'b4406231-f156-49cb-b3b1-be9e352ea01e',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          customFilterTabDisplay: (val) => {
            if (val === 'accepted' || val === 'fully_accepted') {
              return 'Accepted'
            }
            if (val === 'rejected') {
              return 'Rejected'
            }
            if (val === 'offered' || val === 'fully_offered') {
              return 'Offered'
            }
            return ''
          },
          predefined: [
            {
              text: 'Accepted',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'accepted',
            },
            {
              text: 'Offered',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'offered',
            },
            {
              text: 'Rejected',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'rejected',
            },
          ],
        },
        {
          _t_id: 'e1046a43-8300-444a-b06f-02c81173ed89',
          prop: 'active',
          text: 'Active',
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          defaultHidden: false,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '7ad9e0fb-3f7e-43e3-9560-361dda2b7278',
          prop: 'charterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'f789420f-7b45-4f40-acff-1d778ab3f65c',
          prop: 'isAutoReferred',
          text: 'Auto Referred',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '5a53af17-bb06-47f2-9fff-cd8c6dfd6c7e',
          prop: 'isAutoRejected',
          text: 'Auto Rejected',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'd4287760-2a2f-4470-a360-8426b6e6031b',
          prop: 'referredOnConvert',
          text: 'Referred On Convert',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
          detail: false,
          defaultHidden: false,
        },
        {
          _t_id: 'b83ad043-7b0f-42ed-8981-ad3552fb2ca5',
          prop: 'rejectionMethod',
          text: 'Rejection Method',
          computedText: (item, row) => {
            if ([1, 2, 3].includes(item)) {
              return this.mapCategoryText(rejectionMethodMap, item)
            }
            if (
              row.operatorCancellationClassificationId &&
              row.operatorCancellationStatusKey == 'cancelled'
            ) {
              return 'Self-Cancellation'
            }
            if (
              row.cancellationParentClassificationId ==
              CHARTERUP_INITIATED_CANCELLATION_CLASSIFICATION_ID
            ) {
              return 'CharterUP-Cancellation'
            }
            if (
              row.cancellationParentClassificationId ==
              CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID
            ) {
              return 'Client-Cancellation'
            }
            return ''
          },
          sort: true,
          filter: true,
          sortProp: 'rejectionMethod',
          filterProp: 'rejectionMethod',
          type: 'number',
          filterType: 'eq',
          detail: false,
          defaultHidden: false,
          predefined: [
            {
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  filterType: 'contains',
                  component: RejectionMethodFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'bec00ae3-0987-4fa5-9820-635b101dd6ea',
          prop: 'operatorClickedRequestCancellation',
          text: 'Request Cancel Click',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          sortProp: 'operatorClickedRequestCancellation',
          filterProp: 'operatorClickedRequestCancellation',
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        }
      )

      // Hidden columns

      columnsOut.push(
        {
          _t_id: '6749c23c-329d-4c79-a39d-2d3a8d1a5697',
          prop: 'companyId',
          text: 'Company ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'cc81cb5f-affe-444a-8664-9950ddf9ccc9',
          prop: 'bookedByName',
          text: 'Booked By',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          predefined: userPredefined,
          defaultHidden: true,
        },
        {
          _t_id: '9563e5f5-c2c0-4fe2-bfcf-7e0e082680c1',
          prop: 'amount',
          text: 'Referral Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          defaultHidden: true,
        },
        {
          _t_id: '3a731655-1cbc-496a-a781-589d6628bf4e',
          prop: 'referralAcceptanceExpiration',
          text: 'Referral Acceptance Expiration',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
          defaultHidden: true,
        },
        {
          _t_id: 'd2d3376b-d2d9-4d2b-b981-1fcade0d5456',
          prop: 'referralAcceptanceExpirationLabel',
          text: 'Referral Acceptance Expiration Label',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '3007ee3e-bf5f-4db5-977b-131a555273b6',
          prop: 'parentReservation.reservationStatus',
          text: 'Reservation Status',
          computedText: (item, row) =>
            row.reservationStatus ? capitalize(row.reservationStatus) : '',
          predefined: reservationStatusMap.map((item) => {
            return {
              text: item.text,
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: item.value,
            }
          }),
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          detail: false,
          defaultHidden: true,
        },
        {
          _t_id: '3aef03ff-6bcf-4589-9eca-c4883921a731',
          prop: 'operatorCancellationPenalty',
          text: 'Cancellation Fee',
          computedText: (item) => (item ? currencyFilter(item) : ''),
          sort: true,
          filter: true,
          sortProp: 'operatorCancellationPenalty',
          filterProp: 'operatorCancellationPenalty',
          type: 'number',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          defaultHidden: true,
        }
      )

      if (this.isReferralRejectionReasonV2Enabled) {
        columnsOut.push({
          _t_id: 'ff98168a-21aa-48c7-a381-6a4c26623cae',
          prop: 'referralRejectionReasonClassificationLabel',
          text: 'Rejection Reason',
          sort: true,
          filter: true,
          computedText: (item) => item || 'None Selected',
          type: 'text',
          filterType: 'contains',
          defaultHidden: true,
        })
      }

      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const requestId = v4()
      this.requestIdQueue.push(requestId)
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.selectedRows = []
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'referrals/referralsTV',
        params
      )
      if (this.requestIdQueue[this.requestIdQueue.length - 1] != requestId) {
        this.requestIdQueue = this.requestIdQueue.filter(
          (id) => id != requestId
        )
        return
      }
      this.requestIdQueue = this.requestIdQueue.filter((id) => id != requestId)
      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
      if (reservationData) {
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
      }
      EventBus.$emit('reservations-refreshed')
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.local() > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.reservationId !== targetRow.reservationId
      )
    },
  },
}
</script>
